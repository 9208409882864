<template>
    <footer class="footer bg-dark text-white text-center py-2 fixed-bottom">
      <div class="container">
        <span>© 2024 - 2025 WASE. All rights reserved.</span>
      </div>
    </footer>
  </template>
  
  <script>
  export default {
    name: 'FooterDesktop',

  }
  </script>
  
  <style scoped>
 
  </style>